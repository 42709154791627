<template>
  <div class="buying-box">
    <div v-for="label in goodsList" :key="label.id" class="label-item">
      <div class="flex pl8 pr8 pt12 pb12">
        <img :src="label.img" alt="" class="img-class flex-s0 flex-g0" />
        <div class="ml12 flex-s1 flex-g1">
          <div class="fs14 cl17 fw400 txt-line-2 mb8 good-name">
            {{ label.name }}
          </div>
          <div class="flex flex-align-center stock-box">
            <div class="type">{{ label.type }}</div>
            <div class="fs12 fw400 cl99">
              库存：{{ label.reset }}/{{ label.stock }} {{ label.unit }}
            </div>
          </div>
          <div class="flex price-box">
            <div class="now-price">
              <span class="fs12 cl34">抢购价</span>
              <span class="fs12 cl34 price-RMB">￥</span>
              <span
                class="fs20 fw500 cl34"
                v-if="label?.now_price?.split('.')[0]"
              >
                {{ label?.now_price?.split('.')[0] }}
              </span>
              <span
                class="fs12 cl34 fw500"
                v-if="label?.now_price?.split('.')[1]"
              >
                .{{ label?.now_price?.split('.')[1] }}
              </span>
            </div>
            <div class="old-price fs14">
              <span>￥{{ label.price }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex-g0 flex-s0 mt18">
          <van-button
            round
            type="primary"
            size="small"
            :disabled="label.inventory_total == 0"
            @click="handleShare(label)"
          >
            发送
          </van-button>
          <div class="edit" @click.stop="toEdit({
            id: '222'
          })">编辑</div>
        </div> -->
      </div>
      <div class="bdb"></div>
    </div>
    <div class="title">订单列表（3/3）</div>
    <div class="order-list-box">
      <div class="order-list" v-for="(item, index) in orderList" :key="index">
        <div class="order-info">
          <div class="number">
            <div class="num">订单编号：{{ item.number }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
          <div class="status">
            {{ item.status }}
          </div>
        </div>
        <div class="member-info">
          <div class="member-content">
            <div class="member-box">
              <img :src="item.member_info.img" alt="" />
              <div class="member-name">
                <div class="name">
                  <span>
                    {{ item.member_info.name }}
                  </span>
                  <span class="member-type">
                    {{ item.member_info.type == 1 ? '@微信' : '' }}
                  </span>
                  <img
                    src="https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/applet/img/common/man.png"
                    alt=""
                  />
                </div>
                <div class="nick-name">
                  昵称：{{ item.member_info.nick_name }}
                </div>
              </div>
            </div>
            <div
              class="add"
              :class="[item.member_info.is_add ? 'is_add' : 'not_add']"
            >
              {{ item.member_info.is_add ? '联系客户' : '添加客户' }}
            </div>
          </div>
          <div class="address-box">
            <div class="address-info">
              <span>收货信息：</span>
              <div class="detail">
                <p>
                  {{ item.member_info.name }}，{{ item.member_info.moblie }}
                </p>
                <p>{{ item.member_info.address }}</p>
              </div>
            </div>
            <div class="soruce">
              <span>来源：</span>
              <div>{{ item.member_info.source }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const route = useRoute()
const store = useStore()
const goodsList = ref([])
const orderList = ref([])
if (route.query.id) {
  const res = store.state.mockGoods.list.find(
    (item) => item?.id == route.query.id
  )
  if (res) {
    goodsList.value = [res]
    orderList.value = [
      {
        number: 'HI38793819237982',
        time: '2021-08-26 15:09:58',
        status: '已支付',
        member_info: {
          name: '陈妍蓓',
          type: '1', // 1: 微信
          sex: '1', // 1: 女
          nick_name: '陈大大',
          img:
            'http://wx.qlogo.cn/mmhead/kqodNCVWpEsWQWNeY5N0tiaZxLXYf6RiaYWENm6ITmqf4VV2NSZ8pY0g/0',
          is_add: false,
          moblie: '15629786783',
          address: '广东省 广州市 白云区 沙凤一路浔峰花苑北区',
          source: '【微客群店 留学生创业大厦二期 ①群】群聊'
        }
      },
      {
        number: 'HI38793819232584',
        time: '2021-08-26 15:09:58',
        status: '已支付',
        member_info: {
          name: '许勋辉',
          type: '1', // 1: 微信
          sex: '1', // 1: 女
          nick_name: '许勋辉',
          img:
            'https://wework.qpic.cn/bizmail/VhBm9lo5kMHd0krbVSsRshNv83gd7QNBbvVLpcB1fzTPianHVjWQs0g/100',
          is_add: true,
          moblie: '15629786783',
          address: '广东省 广州市 白云区 沙凤一路浔峰花苑北区',
          source: '【微客群店 留学生创业大厦二期 二群】群聊'
        }
      },
      {
        number: 'HI38793819214578',
        time: '2021-08-26 15:09:58',
        status: '已支付',
        member_info: {
          name: 'jan',
          type: '1', // 1: 微信
          sex: '1', // 1: 女
          nick_name: '许勋辉',
          img:
            'https://wework.qpic.cn/bizmail/BsofmpFJ7JRDLp6CWuuNqFwqIrD9l32rZ0jfaMhNktjoMToZic7VjiaQ/100',
          is_add: true,
          moblie: '15629786783',
          address: '广东省 广州市 白云区 沙凤一路浔峰花苑北区',
          source: '【微客群店 留学生创业大厦二期 三群】群聊'
        }
      }
    ]
  }
} else {
  goodsList.value = []
}
</script>
<style>
#app {
  background-color: #fff;
}
</style>
<style lang="less" scoped>
.order-info {
  padding: 8px 15px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .num {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #171717;
    line-height: 22px;
  }
  .time {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 12px;
    color: #8c8c8c;
    letter-spacing: 0;
    line-height: 18px;
  }
  .status {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.member-info {
  padding: 12px 15px;
  .address-box {
    > div {
      display: flex;
      span {
        min-width: 60px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #000000d9;
        text-align: right;
        line-height: 22px;
      }
      div {
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #000000a6;
        line-height: 22px;
      }
    }
  }
  .member-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .add {
      width: 72px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #eff5ff;
      border-radius: 5px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 12px;
      &.not_add {
        background-color: #fff6ef;
        color: #ff6f00;
      }
      &.is_add {
        color: #1773fa;
        background-color: #eff5ff;
      }
    }
  }
  .member-box {
    display: flex;
    img {
      width: 39px;
      height: 39px;
      border-radius: 5px;
    }
    .member-name {
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 14px;
      color: #000000d9;
      line-height: 18px;
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .member-type {
        font-family: PingFangSC-Light;
        font-weight: 300;
        font-size: 12px;
        color: #00c000;
        line-height: 18px;
        margin-right: 4px;
        margin-left: 4px;
      }
      img {
        width: 14px;
        height: 14px;
      }
      .nick-name {
        font-family: PingFangSC-Light;
        font-weight: 300;
        font-size: 12px;
        color: #999999;
      }
    }
  }
}
.title {
  padding: 12px 15px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 16px;
  color: #171717;
}
.good-name {
  height: 38px;
}
.type {
  width: 34px;
  height: 18px;
  background-image: linear-gradient(117deg, #fd8b04 18%, #f86e08 79%);
  border-radius: 2px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  margin-right: 8px;
}
.stock-box {
  margin-bottom: 2px;
}
.price-box {
  height: 30px;
  .now-price {
    margin-right: 4px;
    .price-RMB {
      letter-spacing: -2px;
    }
  }
  .old-price {
    align-self: flex-end;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
    letter-spacing: 0;
    line-height: 18px;
    text-decoration: line-through;
  }
}
.text-color {
  color: rgba(23, 23, 23, 0.6);
}
.container {
  height: 100vh;
}
.container-header {
  padding: 14px 15px 16px 15px;
}
.container-wrapper {
  overflow: hidden;

  .menu-wrapper,
  .label-wrapper {
    height: calc(100vh - 109px);
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
  }
  .menu-wrapper::-webkit-scrollbar {
    display: none;
  }
  .menu-wrapper {
    width: 90px;
    padding-bottom: 140px;
    background-color: #f7f7f7;

    :deep(.van-sidebar) {
      width: 100%;

      .van-sidebar-item {
        background-color: #f7f7f7;
        padding: 12px 15px;
      }
      .van-sidebar-item--select {
        background-color: #fff;
      }
      .van-sidebar-item--select::before {
        width: 0;
      }
    }
    .menu-item-last {
      border-bottom-right-radius: 6px;
    }
    .menu-item-next {
      border-top-right-radius: 6px;
    }
  }
  .label-wrapper {
    padding: 0 8px;

    .label-cell {
      .label-item {
        padding: 4px 0;
        margin-bottom: 4px;
        background-color: #fff;
        .label-item-title {
          height: 22px;
          line-height: 22px;
          padding: 0 8px;
          border-radius: 2px;
          color: rgba(0, 0, 0, 0.65);
          background: rgba(0, 0, 0, 0.04);
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        .label-item-active {
          color: #fff;
          background: rgba(255, 111, 0, 0.8);
          border: none;
        }
      }
    }
  }
}
// 模拟搜索框
.imitate-search-box {
  padding: 12px 16px;
  padding-top: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .imitate-input {
    line-height: 32px;
    background-color: #f5f5f5;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    border-radius: 5px;
    cursor: pointer;

    flex-grow: 1;
    .imitate-placehodor {
      margin-left: 8px;
    }
  }
}
.img-class {
  width: 99px;
  height: 99px;
}
.label-item {
  background-color: #fff;
}
</style>
